<template>
  <div id="survey-question">
    <div class="survey-list" v-for="survey in surveyList" v-bind:key="survey.id" @click="showDetail(survey.id)">
      <label>{{ survey.surveyName }}</label>
      <div>{{ period(survey) }}</div>
    </div>
  </div>
</template>
<script>

import moment from 'moment';
import { makeRequestByAes } from '@/utils/make-request-data.js'
import { getMemberCode, getRequestSecretKey } from '@/utils/common.js'
import { getSurveyQuestions } from '@/api/survey.js'
import { apiError } from '@/api/common.js'

export default {
  data() {
    return {
      DATE_FORMAT: 'yyyy.MM.DD',
      memberCode: this.getMemberCode(),
      surveyList: []
    };
  },
  async beforeMount() {
    // 念のため空にする
    this.surveyList.length = 0;
    // 調査一覧を取得する
    const surveyListData = await this.getSurveyList()
    if (!surveyListData) return
    const surveyList = surveyListData.value;
    Array.prototype.push.apply(this.surveyList, surveyList);
  },
  methods: {
    getMemberCode: function() {
      return sessionStorage.getItem('login_member_code');
    },
    getSurveyList: async function() {
      const memberCode = getMemberCode();

      // リクエスト秘密鍵
      const requestSecretKey = getRequestSecretKey();
      if (!requestSecretKey) {
        // alert('request secret key is not found!');
        alert('ログアウトされています。再度ログインしてください。');
        this.$router.push({name: 'LoginPage'})
        return;
      }

      // 非公開鍵暗号通信のデータ作成
      const sendData = makeRequestByAes(
        memberCode,
        requestSecretKey,
        {
          member_code: memberCode,
        }
      );

      // 非公開鍵暗号通信でトランザクション情報を取得
      const result = await getSurveyQuestions(sendData).catch(apiError);
      if (!result || !result.value) {
        // alert('Fetch SurveyQuestions Data failed');
        alert('ログアウトされています。再度ログインしてください。');
        this.$router.push({name: 'LoginPage'})
        return;
      }

      return result;
    },
    showDetail(surveyId) {
      this.$router.push({name: 'Answer', params: {id: surveyId}})
    },
  },
  computed: {
    period: function() {
      return function(survey) {
        let message = "";
        message = message + "期間："
                          + moment(survey.startDate).format(this.DATE_FORMAT)
                          + " ～ "
                          + moment(survey.finishDate).format(this.DATE_FORMAT);
        return message;
      }
    }
  }
};
</script>

<style scoped>
#survey-question {
  display: inline-block;
}
.survey-list {
  margin: 5px;
  margin-bottom: 20px;
  border: 1px solid;
  width: 500px;
}
.survey-list, .survey-list * {
  cursor: pointer;
}
</style>
